<template>
  <v-app>
    <v-row
      ><v-col class="d-flex justify-center align-center">
        <div>
          <v-img
            id="login"
            max-width="300"
            min-width="200"
            src="@/assets/images/logo.png"
            alt="company-logo"
          />
        </div>
      </v-col>
      <v-col class="d-flex justify-center align-center">
        <v-card width="500" class="mx-auto mt-5 p-20 transparent" flat>
          <form @submit.prevent="submit">
            <v-card-text>
              <v-alert
                v-show="errors"
                transition="slide-y-transition"
                dismissible
                color="error"
                icon="mdi-alert-circle"
                dense
                text
                >{{ $t('alerts.loginError') }}</v-alert
              >

              <v-text-field
                v-model="user.email"
                label="Email"
                clearable
                prepend-icon="mdi-email"
                autocomplete="username"
                @change="resetAlert"
              />

              <v-text-field
                v-model="user.password"
                label="Password"
                prepend-icon="mdi-lock"
                :type="showPassword ? 'text' : 'password'"
                :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
                autocomplete="current-password"
                @click:append="showPassword = !showPassword"
                @change="resetAlert"
                @keyup.enter="submit"
              />
            </v-card-text>
            <v-divider></v-divider>
            <v-card-actions>
              <v-btn
                :loading="isLoading"
                class="white--text"
                color="primary"
                depressed
                block
                type="submit"
                >Login</v-btn
              >
            </v-card-actions>
            <a
              class="pl-2"
              :href="
                `https://sso.chaingotech.com/${lang}/recover?redirect=https://forestchain.chaingotech.com/${lang}/dashboard&sender=Forestchain`
              "
              target="_blank"
              >Forgot your password?</a
            >
          </form>
        </v-card></v-col
      ></v-row
    >
  </v-app>
</template>

<script>
export default {
  props: {
    lang: {
      type: String,
      default: 'en'
    }
  },
  data() {
    return {
      showPassword: false,
      isLoading: false,
      errors: false,
      user: {}
    }
  },
  methods: {
    async submit() {
      this.isLoading = true
      try {
        const authUser = await this.$store.dispatch('auth/login', {
          username: this.user.email,
          password: this.user.password,
          grant_type: 'password'
        })
        if (authUser.attributes.roles.includes('user'))
          this.$router.replace({ name: 'sale-order-customer-index' })
        else
          this.$router.replace(
            this.$route.query.redirect || { name: 'dashboard-show' }
          )
        this.isLoading = false
      } catch {
        this.errors = true
        this.isLoading = false
      }
    },
    resetAlert() {
      this.errors = false
    }
  }
}
</script>
